import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import useMediaQuery from 'hooks/use-media-query'
import {
  Center,
  Section,
  SectionTitle,
  ButtonWrapper,
  SectionEyebrow,
} from '../styled'
import { Paragraph, ImgWrapper } from './styled'
import { OutlineButtonLink } from 'components/button'
import urls from 'common/urls'
import { ScreenSize } from 'styles/mq'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  routineTestHero: FluidImage
  mobileRoutineTestHero: FluidImage
}

const query = graphql`
  query {
    routineTestHero: file(
      relativePath: { eq: "routine-test-hero-desktop.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 85, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobileRoutineTestHero: file(
      relativePath: { eq: "routine-test-hero-mobile.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const RoutineTest = () => {
  const { routineTestHero, mobileRoutineTestHero } = useStaticQuery<
    QueryResult
  >(query)

  const isDesktop = useMediaQuery(`(min-width: ${ScreenSize.D}px)`)

  return (
    <Section gray>
      <Center>
        <SectionEyebrow centered>Our Vision</SectionEyebrow>
        <SectionTitle centered>
          We see a routine test as a potential
          <br />
          canary in a coal mine
        </SectionTitle>
        <Paragraph>
          With patient consent, each test contributes to a biobank of semen
          samples that can be used to yield breakthroughs across medical
          disciplines.
        </Paragraph>
        <ButtonWrapper>
          <OutlineButtonLink to={urls.science}>Our science</OutlineButtonLink>
        </ButtonWrapper>
        <ImgWrapper>
          <Img
            fluid={
              isDesktop
                ? routineTestHero.childImageSharp.fluid
                : mobileRoutineTestHero.childImageSharp.fluid
            }
            imgStyle={{ objectFit: 'contain' }}
          />
        </ImgWrapper>
      </Center>
    </Section>
  )
}

export default RoutineTest
