import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const ChatOutline: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 41 41">
    <path
      stroke="currentColor"
      strokeWidth="1.75"
      fill="none"
      d="M29.365 24.785a1.475 1.475 0 1 0 .886-2.679m.22-8.577A11.054 11.054 0 0 1 36 23.11c0 3.138-1.308 5.972-3.408 7.985-.254.243-.41.576-.41.928 0 .93.178 1.852.525 2.715l.292.727a.521.521 0 0 1-.65.688l-1.212-.407a3.357 3.357 0 0 1-2.002-1.827.582.582 0 0 0-.718-.308 11.05 11.05 0 0 1-3.476.557c-1.92 0-3.728-.49-5.302-1.351m8.478-13.757c0 6.107-4.95 11.058-11.058 11.058a11.05 11.05 0 0 1-3.477-.557.582.582 0 0 0-.717.308 3.357 3.357 0 0 1-2.002 1.827l-1.212.407a.521.521 0 0 1-.65-.688l.292-.727a7.287 7.287 0 0 0 .525-2.715c0-.352-.156-.684-.41-.928A11.027 11.027 0 0 1 6 19.059C6 12.95 10.951 8 17.059 8c6.107 0 11.058 4.951 11.058 11.059Zm-14.916.752a1.475 1.475 0 1 1-2.409-1.702 1.475 1.475 0 0 1 2.409 1.702Zm5.175 0a1.475 1.475 0 1 1-2.408-1.702 1.475 1.475 0 0 1 2.409 1.702Zm5.303 0a1.475 1.475 0 1 1-2.409-1.702 1.475 1.475 0 0 1 2.409 1.702Z"
    />
  </Svg>
)

export default ChatOutline
