import React from 'react'

import Container from 'components/container'
import PersonOutlineCheckmark from 'components/icons/person-outline-checkmark'
import PeopleOutlineClock from 'components/icons/people-outline-clock'
import Workflows from 'components/icons/workflows'
import { Section, SectionEyebrow, SectionTitle } from '../styled'
import { Triad, TriadItem, ItemIcon, ItemTitle, ItemParagraph } from './styled'

const WorkingWithUs = () => {
  return (
    <Section>
      <Container>
        <SectionEyebrow centered>Working With Us</SectionEyebrow>
        <SectionTitle centered>
          Clinics that work with <br />
          <span translate="no">Fellow </span>report:
        </SectionTitle>
        <Triad>
          <TriadItem>
            <ItemIcon>
              <PeopleOutlineClock />
            </ItemIcon>
            <ItemTitle>Increased time with patients</ItemTitle>
            <ItemParagraph>
              Less time spent handling logistics and answering patient questions
              frees up more time for one-on-one care.
            </ItemParagraph>
          </TriadItem>
          <TriadItem>
            <ItemIcon>
              <Workflows />
            </ItemIcon>
            <ItemTitle>More efficient workflows</ItemTitle>
            <ItemParagraph>
              With workflows that fit into your practice, you can focus on
              running your clinic — not semen analysis.
            </ItemParagraph>
          </TriadItem>
          <TriadItem>
            <ItemIcon>
              <PersonOutlineCheckmark />
            </ItemIcon>
            <ItemTitle>
              Increased compliance<sup>†‡</sup>
            </ItemTitle>
            <ItemParagraph>
              We track which patients received a kit — and send instructions and
              reminders — to help ensure that patients have all the information
              they need.
            </ItemParagraph>
          </TriadItem>
        </Triad>
      </Container>
    </Section>
  )
}

export default WorkingWithUs
