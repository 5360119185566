import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const PeopleOutlineClock: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 52 52" fill="none">
    <path
      strokeWidth="1.75"
      stroke="currentColor"
      fill="none"
      d="M41.5 45a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
    />
    <path
      strokeWidth="1.75"
      stroke="currentColor"
      fill="none"
      d="M41 33.5V38h4"
    />
    <path
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      d="M14.6 20.647A5.324 5.324 0 1 0 14.6 10a5.324 5.324 0 0 0 0 10.647Z"
    />
    <path
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      d="M22.7 24.08H13.55A7.553 7.553 0 0 0 6 31.63v4.022"
    />
    <path
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      d="M30.98 22.285a5.323 5.323 0 1 0 0-10.647 5.323 5.323 0 0 0 0 10.647Z"
    />
    <path
      strokeWidth="2"
      stroke="currentColor"
      fill="none"
      d="M37.41 26.954H28.26a7.553 7.553 0 0 0-7.551 7.55v4.022"
    />
  </Svg>
)

export default PeopleOutlineClock
