import urls from 'common/urls'
import { ExternalLink } from 'components/links'
import React from 'react'
import { FlexCenter, Section, SectionTitle } from '../styled'
import { Paragraph } from './styled'

const SimpleAndConvenient = () => {
  return (
    <Section>
      <FlexCenter>
        <SectionTitle centered>
          Working with<span translate="no"> Fellow </span>is simple
          <br />
          and convenient
        </SectionTitle>
        <Paragraph>
          We offer urologists, fertility specialists, and OBGYNs around the
          country a variety of options to make our tests accessible to your
          patients. Request more information to hear from one of our local
          representatives, or write us directly at{' '}
          <a href="mailto:sales@meetfellow.com">sales@meetfellow.com</a>.
        </Paragraph>
        <ExternalLink to={urls.leadGenForm}>Request information</ExternalLink>
      </FlexCenter>
    </Section>
  )
}

export default SimpleAndConvenient
