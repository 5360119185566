import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const PersonOutlineGear: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 41 41">
    {/* <mask id="a" width="41" height="41" x="0" y="0" maskUnits="userSpaceOnUse">
      <path fill="none" d="M0 0h41v41H0z" />
    </mask>
    <g mask="url(#a)"> */}
    <path
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.75"
      fill="none"
      d="M17.594 15.313a5.156 5.156 0 1 0 0-10.313 5.156 5.156 0 0 0 0 10.313Z"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.75"
      fill="none"
      d="M19.313 18.75h-2.526C12.488 18.75 9 22.267 9 26.6v4.181"
    />
    <path
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.75"
      fill="none"
      d="M17.593 18.75h2.526a7.753 7.753 0 0 1 6.128 3.008"
    />
    <rect
      width="7.05"
      height="7.05"
      x="26.475"
      y="25.475"
      stroke="currentColor"
      strokeWidth="1.75"
      rx="3.525"
      fill="none"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.441 23a.5.5 0 0 0-.5.5v2.058a3.598 3.598 0 0 1 2.118 0V23.5a.5.5 0 0 0-.5-.5H29.44Zm2.244 2.818c.637.337 1.16.86 1.497 1.497l1.456-1.455a.5.5 0 0 0 0-.707l-.79-.79a.5.5 0 0 0-.707 0l-1.456 1.455Zm1.757 2.123a3.601 3.601 0 0 1 0 2.118H35.5a.5.5 0 0 0 .5-.5V28.44a.5.5 0 0 0-.5-.5h-2.058Zm-.26 2.744a3.616 3.616 0 0 1-1.497 1.497l1.455 1.456a.5.5 0 0 0 .707 0l.79-.79a.5.5 0 0 0 0-.708l-1.455-1.455Zm-2.123 1.757a3.6 3.6 0 0 1-2.118 0V34.5a.5.5 0 0 0 .5.5h1.118a.5.5 0 0 0 .5-.5v-2.058Zm-2.744-.26a3.617 3.617 0 0 1-1.497-1.497l-1.456 1.455a.5.5 0 0 0 0 .707l.79.79a.5.5 0 0 0 .708 0l1.455-1.455ZM26.56 30.06a3.601 3.601 0 0 1 0-2.118H24.5a.5.5 0 0 0-.5.5v1.118a.5.5 0 0 0 .5.5h2.059Zm.259-2.744c.338-.636.86-1.16 1.497-1.497l-1.455-1.456a.5.5 0 0 0-.708 0l-.79.79a.5.5 0 0 0 0 .708l1.456 1.455Z"
    />
    {/* </g> */}
  </Svg>
)

export default PersonOutlineGear
