import styled, { css } from 'styled-components'
import theme from 'styles/theme'

export const SliderButton = styled.button<{ isForward?: boolean }>`
  display: inline-block;
  order: 1;
  appearance: none;
  border: none;
  padding: 0;
  margin: 5rem 1.2rem;
  font: inherit;
  font-size: 2rem;
  color: ${theme.color.accent.primary};
  cursor: pointer;
  background: none;

  svg {
    display: block;
  }

  &[disabled] {
    color: inherit;
    opacity: 0.5;
  }

  ${props =>
    !props.isForward &&
    css`
      transform: rotateZ(180deg);
    `}
`
