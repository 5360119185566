import Urls from 'common/urls'
import { ExternalLink } from 'components/links'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React, { FC } from 'react'
import { Section, SectionEyebrow, SectionTitle } from '../styled'
import {
  ButtonWrapper,
  HeadingWrapper,
  Left,
  LeftRightWrapper,
  Paragraph,
  Right,
} from './styled'

import IconList from 'components/icon-list'
import ChatOutline from 'components/icons/chat-outline'
import Handshake from 'components/icons/handshake'
import PersonOutlineGear from 'components/icons/person-outline-gear'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  dedicatedSupportHero: FluidImage
}

const query = graphql`
  query {
    dedicatedSupportHero: file(
      relativePath: { eq: "dedicated-support-hero.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 85, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const DedicatedSupport: FC = () => {
  const { dedicatedSupportHero } = useStaticQuery<QueryResult>(query)

  const listItems = [
    {
      icon: <PersonOutlineGear />,
      subheading: 'Personalized solutions',
      text:
        'We start by working with your clinic to identify how we can improve your workflows, while also solving for improved patient compliance and satisfaction.',
    },
    {
      icon: <Handshake />,
      subheading: 'Thorough clinic onboarding',
      text:
        "During onboarding we'll walk you through the clinic portal, offer best practices for stocking and storing <span translate='no'>Fellow</span> tests, and supply you with patient collateral.",
      html: true,
    },
    {
      icon: <ChatOutline />,
      subheading: 'Responsive customer care',
      text:
        'Your time—and your patients’—is important to us. We answer questions typically within the hour.',
    },
  ]

  return (
    <Section>
      <HeadingWrapper>
        <SectionEyebrow>Our Team</SectionEyebrow>
        <SectionTitle>Dedicated support at every step</SectionTitle>
        <Paragraph>
          Our team works closely with you to be sure your clinic has everything
          it needs to successfully offer patients a smooth, positive
          experience—and to answer questions if you need help.
        </Paragraph>
      </HeadingWrapper>
      <LeftRightWrapper>
        <Left>
          <IconList items={listItems} />
          <ButtonWrapper>
            <ExternalLink to={Urls.leadGenForm}>Get in touch</ExternalLink>
          </ButtonWrapper>
        </Left>
        <Right>
          <Img
            fluid={dedicatedSupportHero.childImageSharp.fluid}
            imgStyle={{ objectFit: 'contain' }}
          />
        </Right>
      </LeftRightWrapper>
    </Section>
  )
}

export default DedicatedSupport
