import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import useMediaQuery from 'hooks/use-media-query'
import { Center, Section, SectionEyebrow, SectionTitle } from '../styled'
import { Paragraph, ImgWrapper } from './styled'
import { ScreenSize } from 'styles/mq'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  comprehensiveViewHero: FluidImage
  mobileComprehensiveViewHero: FluidImage
}

const query = graphql`
  query {
    comprehensiveViewHero: file(
      relativePath: { eq: "comprehensive-view-hero-desktop.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 85, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobileComprehensiveViewHero: file(
      relativePath: { eq: "comprehensive-view-hero-mobile.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ComprehensiveView = () => {
  const { comprehensiveViewHero, mobileComprehensiveViewHero } = useStaticQuery<
    QueryResult
  >(query)

  const isDesktop = useMediaQuery(`(min-width: ${ScreenSize.D}px)`)

  return (
    <Section>
      <Center>
        <SectionEyebrow centered>Our Clinic Portal</SectionEyebrow>
        <SectionTitle centered>
          A comprehensive view of your
          <br />
          patient&apos;s testing journey
        </SectionTitle>
        <Paragraph>
          Our clinic portal saves you time and hassle by giving you a way to
          view your patients&apos; testing progress and results.
        </Paragraph>
        <ImgWrapper>
          <Img
            fluid={
              isDesktop
                ? comprehensiveViewHero.childImageSharp.fluid
                : mobileComprehensiveViewHero.childImageSharp.fluid
            }
            imgStyle={{ objectFit: 'contain' }}
          />
        </ImgWrapper>
      </Center>
    </Section>
  )
}

export default ComprehensiveView
