import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const Handshake: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 41 41">
    <path
      d="m28.067 25.298-7.507-6.045m7.508 6.045-2.241 2.385a1.042 1.042 0 0 1-1.359.138 1.042 1.042 0 0 0-1.31.088l-.746.695c-.451.42-1.138.455-1.63.085a1.272 1.272 0 0 0-1.715.173l-.423.477c-.373.42-.908.661-1.47.661h-.267a2 2 0 0 1-.905-.216l-3.763-1.909c-.701-.355-1.517-.465-2.217-.823C9.438 26.754 9 26.242 9 25.63v-9.02c0-.49.215-.958.594-1.294l3.563-2.705A3 3 0 0 1 14.97 12h5.232l1.788.535m6.078 12.763 1.18-.098A3 3 0 0 0 32 22.21v-6.533a.79.79 0 0 0-.79-.79.791.791 0 0 1-.392-.104l-4.63-2.632a1.158 1.158 0 0 0-.572-.151h-2.274c-.503 0-.986.191-1.352.535m.715 4.702-3.307 3.107a1.958 1.958 0 0 1-2.682-2.854l5.274-4.955"
      stroke="currentColor"
      strokeWidth="1.75"
      fill="none"
    />
    <path
      d="M7.5 28H7a3 3 0 0 1-3-3v-8.5a2.5 2.5 0 0 1 5 0v8.875"
      stroke="currentColor"
      strokeWidth="1.75"
      fill="none"
    />
    <path
      d="M32.768 27H34a3 3 0 0 0 3-3v-8.5a2.5 2.5 0 0 0-5 0v6.762"
      stroke="currentColor"
      strokeWidth="1.75"
      fill="none"
    />
  </Svg>
)

export default Handshake
