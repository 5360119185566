import styled from 'styled-components'
import { heading, paragraph } from 'styles/typography'
import mq from 'styles/mq'

export const SectionTitle = styled.h2`
  ${heading.l};
  text-align: center;
  max-width: 90rem;
  margin: 0 auto 2rem auto;
`

export const Paragraph = styled.p`
  ${paragraph.small}
  max-width: 60rem;
  margin: auto auto 1.6rem;
  text-align: center;
`

// Counteract the section padding to make the image full-width on mobile
// For the desktop image, counteract the blank image space with negative margin
export const ImgWrapper = styled.div`
  .gatsby-image-wrapper {
    margin: 0 -2.4rem;
  }

  ${mq.medium} {
    margin: 0 0 -5.4rem;
  }
`
