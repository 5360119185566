import styled from 'styled-components'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'

export const SectionTitle = styled.h2`
  ${heading.l};
  text-align: center;
  max-width: 90rem;
  margin: 0 auto 2rem auto;
`

export const Paragraph = styled.p`
  ${paragraph.small}
  max-width: 70ch;
  margin: 0 auto 4rem;
  text-align: center;
`

export const ImgWrapper = styled.div`
  color: ${theme.color.text.brown};
  width: 20rem;
  margin: 0 auto 2rem;

  svg {
    width: 20rem;
    height: 14rem;
  }
`
