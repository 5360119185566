import React from 'react'

import Layout from 'components/layout'
import SEO from 'components/seo'
import Hero from './hero'
import WorkingWithUs from './working-with-us'
import ComfortableTesting from './comfortable-testing'
import ComprehensiveView from './comprehensive-view'
import DedicatedSupport from './dedicated-support'
import StabilizationTechnology from './stabilization-technology'
import PaperSection from './paper-section'
import RoutineTest from './routine-test'
import TestimonialsCarousel from 'components/testimonials-carousel'
import SimpleAndConvenient from './simple-and-convenient'
import ReferencesFootnote from 'components/references-footnote'
import StickyFooter from 'components/sticky-footer'

const CliniciansPage = () => {
  const references = [
    {
      symbol: '§',
      content:
        'Samplaski et al. Development and validation of a novel mail-in semen analysis system and the correlation between one hour and delayed semen analysis testing; Fertil Steril. 2021;115(4):922-929',
    },
    {
      symbol: '†',
      content:
        'Tolani et al. Mail-in semen analysis breaks down racial, educational, and income barriers to semen analysis completion. Proceedings from the Pacific Coast Reproductive Society (PCRS) Annual Meeting 2024. Indian Wells, CA.',
    },
    {
      symbol: '‡',
      content:
        'Gu et al. High compliance with Fellow semen analysis testing across the United States irrespective of clinical practice type. Proceedings from the American Urological Association Annual Meeting 2024. San Antonio, TX. ',
    },
    {
      symbol: '*',
      content: `
        Multiple publications report only 1 in 2 patients complete post-vasectomy semen
        analysis, including <a href="https://www.goldjournal.net/article/S0090-4295(19)31026-X/fulltext" target="_blank" rel="noopener noreferrer">[1]</a> Bradshaw et al., Urology, 2019,
        <a href="https://www.auajournals.org/doi/abs/10.1016/j.juro.2013.01.062" target="_blank" rel="noopener noreferrer"> [2] </a>
        Duplisea and Whelan, Journal of Urology, 2013 and
        <a href="https://www.scitechnol.com/peer-review/post-vasectomy-semen-analysis-factors-predicting-compliance-xgLE.php?article_id=9391" target="_blank" rel="noopener noreferrer"> [3] </a>
        Johnson (Siegert) et al., Andrology & Gynecology: Current Research, 2019.
      `,
      html: true,
    },
  ]

  return (
    <Layout>
      <SEO
        title="For Clinicians | Improve Patient Experience"
        description="We help clinicians streamline their workflows and improve patient experience."
      />
      <Hero />
      <WorkingWithUs />
      <ComfortableTesting />
      <ComprehensiveView />
      <DedicatedSupport />
      <StabilizationTechnology />
      <PaperSection />
      <RoutineTest />
      <TestimonialsCarousel
        eyebrow="Success Stories"
        header="Helping hundreds of providers move patient care forward"
        showDoctors
        dark
        showImages={false}
      />
      <SimpleAndConvenient />
      <ReferencesFootnote references={references} />
      <StickyFooter />
    </Layout>
  )
}

export default CliniciansPage
