import React, { FC } from 'react'
import Container from 'components/container'
import { Section, Reference } from './styled'

type Props = {
  references: ReadonlyArray<{
    symbol: string  // '*' | '**' | '†'
    content: string
    html?: boolean
  }>
}

const ReferencesFootnote: FC<Props> = ({ references = [] }) => {
  return (
    <Section>
      <Container>
        {references.map(reference => (
          reference.html ? (
            <Reference
              key={reference.symbol}
              dangerouslySetInnerHTML={{__html: `${reference.symbol} ${reference.content}`}}
            />
          ) : (
            <Reference key={reference.symbol}>{reference.symbol} {reference.content}</Reference>
          )
        ))}
      </Container>
    </Section>
  )
}

export default ReferencesFootnote
