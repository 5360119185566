import styled from 'styled-components'
import theme from 'styles/theme'
import { heading } from 'styles/typography'
import mq from 'styles/mq'
import Button from 'components/button'

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 8rem;
  padding: 1.2rem 4rem;
  display: flex;
  justify-content: flex-end;
  background-color: ${theme.color.background.light};
  box-shadow: 0 -0.3rem 0.5rem 0 rgba(0, 0, 0, 0.05);
`

export const TextContainer = styled.div`
  display: none;

  ${mq.medium} {
    display: flex;
    align-items: center;
    padding: 0.6rem 3rem 0 0;
  }
`

export const Headline = styled.h1`
  ${heading.xs};
  margin-bottom: 0;
  text-align: right;
`

// TODO: remove this if we get things working without the <a> tag
export const CTAButton = styled(Button as any)``