import React from 'react'
import { FlexCenter, Section, SectionTitle } from '../styled'
import { Paragraph, ImgWrapper } from './styled'
import { OutlineButtonLink } from 'components/button'
import ClockWithSperm from 'components/icons/clock-with-sperm'
import urls from 'common/urls'

const StabilizationTechnology = () => {
  return (
    <Section dark>
      <FlexCenter>
        <ImgWrapper>
          <ClockWithSperm />
        </ImgWrapper>
        <SectionTitle centered>
          Clinically validated analysis powered by
          <br />
          <span translate="no"> Fellow&apos;s</span> innovative mail-in kit.
          <sup>§</sup>
        </SectionTitle>
        <Paragraph>
          Our process is peer-reviewed to provide clinically valid results for
          samples produced up to 52 hours before they reach our lab. Our
          proprietary algorithm accounts for sperm&apos;s natural rate of
          degradation over time, allowing us to calculate a sample&apos;s
          metrics just as accurately as if it had been produced and analyzed
          within 1 hour of ejaculation.
        </Paragraph>
        <OutlineButtonLink to={urls.science}>Learn more</OutlineButtonLink>
      </FlexCenter>
    </Section>
  )
}

export default StabilizationTechnology
