import styled from 'styled-components'
import theme from 'styles/theme'
import { paragraph } from 'styles/typography'

export const Section = styled.section`
  text-align: center;
  padding: 1rem 0;
  background: ${theme.color.background.lightGray};
`

export const Reference = styled.p`
  ${paragraph.extraSmall};
  text-align: left;
  color: ${theme.color.text.darkPurple};
`
