import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const Workflows: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 52 52" fill="none">
    <path
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      d="M32.456 10.322 26.134 4l-6.322 6.322 6.322 6.322 6.322-6.322Z"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      d="M15.152 22.388v9.151H6v-9.151h9.152Z"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      d="M38.227 23.6a5.215 5.215 0 1 1 7.374 7.375 5.215 5.215 0 0 1-7.374-7.374Z"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      d="m31.71 46.344-5.497-9.152-5.485 9.133 10.981.019Z"
    />
    <path fill="currentColor" d="m17.683 19.058.51-3.588-3.58.51 3.07 3.078Z" />
    <path stroke="currentColor" strokeWidth="2" d="m14.538 19.13 2.036-2.037" />
    <path
      fill="currentColor"
      d="m34.085 36.587-.511 3.588 3.58-.51-3.07-3.078Z"
    />
    <path stroke="currentColor" strokeWidth="2" d="m37.23 36.517-2.037 2.036" />
    <path
      fill="currentColor"
      d="m33.549 18.527 3.588.51-.51-3.58-3.078 3.07Z"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="m33.477 15.382 2.037 2.036"
    />
    <path
      fill="currentColor"
      d="m18.124 37.028-3.588-.51.51 3.58 3.078-3.07Z"
    />
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="m18.194 40.173-2.036-2.036"
    />
  </Svg>
)

export default Workflows
