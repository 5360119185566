import styled from 'styled-components'
import { heading, paragraph } from 'styles/typography'

export const SectionTitle = styled.h2`
  ${heading.l};
  text-align: center;
  max-width: 90rem;
  margin: 0 auto 2rem auto;
`

export const Paragraph = styled.p`
  ${paragraph.small}
  max-width: 65ch;
  margin: 0 auto 4rem;
  text-align: center;
`
