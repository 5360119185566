import styled from 'styled-components'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'
import mq, { MaxWidth } from 'styles/mq'

export const HeadingWrapper = styled.section`
  max-width: ${MaxWidth / 10}rem;
  margin: 0 auto;

  ${mq.large} {
    padding: 0 8rem;
  }

  ${mq.extraLarge} {
    padding: 0 15rem;
  }
`

export const LeftRightWrapper = styled.section`
  max-width: ${MaxWidth / 10}rem;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'image'
    'text';
  background: ${theme.color.background.light};

  ${mq.medium} {
    padding: 2.2rem 0 0;
  }

  ${mq.desktop} {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'text image';
  }

  ${mq.extraLarge} {
    grid-template-columns: 5fr 4fr;
  }
`

export const Left = styled.div`
  color: ${theme.color.text.dark};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  grid-area: text;

  ${mq.large} {
    padding: 0 8.4rem 9.1rem;
  }

  ${mq.extraLarge} {
    padding: 0 15rem;
  }
`

export const Right = styled.div`
  overflow: hidden;
  pointer-events: none;
  grid-area: image;

  .gatsby-image-wrapper {
    width: 100%;

    ${mq.mobileLarge} {
      height: 50rem;
    }

    ${mq.large} {
      height: 80rem;
    }
  }
`

export const Title = styled.h1`
  ${heading.xxl};
  color: ${theme.color.text.dark};
  grid-area: text;
  margin-top: -4rem;
  margin-block-end: 0.2em;
  z-index: 1;
  pointer-events: none;

  ${mq.extraSmall} {
    font-size: 3.6rem;
  }

  ${mq.small} {
    margin-top: -2rem;
  }

  ${mq.medium} {
    position: relative;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    display: flex;
    align-items: left;
    justify-content: left;
    grid-area: unset;
  }

  ${mq.desktop} {
    font-size: 4.4rem;
    margin-bottom: 0.6rem;
  }
`

export const Paragraph = styled.p`
  ${paragraph.normal};
  margin-bottom: 3rem;
  max-width: 78rem;
`

export const ButtonWrapper = styled.div`
  margin-top: 8rem;
`
