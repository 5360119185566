import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import useMediaQuery from 'hooks/use-media-query'
import { ScreenSize } from 'styles/mq'
import {
  Left,
  Paragraph,
  Right,
  HeadingWrapper,
  LeftRightWrapper,
  IntegerStep,
} from './styled'
import { Section, SectionEyebrow, SectionTitle } from '../styled'
import IconList from 'components/icon-list'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  comfortableTestingHero: FluidImage
  mobileComfortableTestingHero: FluidImage
}

const query = graphql`
  query {
    comfortableTestingHero: file(
      relativePath: { eq: "comfortable-testing-hero.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobileComfortableTestingHero: file(
      relativePath: { eq: "comfortable-testing-hero-mobile.png" }
    ) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ComfortableTesting: FC = () => {
  const {
    comfortableTestingHero,
    mobileComfortableTestingHero,
  } = useStaticQuery<QueryResult>(query)

  const isDesktop = useMediaQuery(`(min-width: ${ScreenSize.D}px)`)

  const listItems = [
    {
      icon: <IntegerStep>01</IntegerStep>,
      subheading: 'Provide a kit to your patient',
      text:
        'Give a<span translate="no"> Fellow </span>kit to your patient for them to take home, or ship a kit directly to them.',
      html: true,
    },
    {
      icon: <IntegerStep>02</IntegerStep>,
      subheading: 'Patient completes the test',
      text:
        "When it's time to test, your patient registers their sample with our lab, produces it at home, and mails it back.",
      html: true,
    },
    {
      icon: <IntegerStep>03</IntegerStep>,
      subheading: 'Analysis',
      text:
        "We analyze your patient's sample and turn the results around in less than 2 business days from when it reaches our CLIA-certified lab.",
    },
    {
      icon: <IntegerStep>04</IntegerStep>,
      subheading: 'Results',
      text:
        'We deliver comprehensive and easy-to-understand results directly to you and your patient.',
    },
  ]

  return (
    <Section dark>
      <HeadingWrapper>
        <SectionEyebrow>How It Works</SectionEyebrow>
        <SectionTitle>
          Offer patients a more comfortable testing experience
        </SectionTitle>
        <Paragraph>
          Our mail-in semen tests dramatically improve the experience of
          patients and clinic staff members, achieving compliance rates of up to
          97%<sup>†‡</sup> (compared to an established average of about 50% for
          in-office testing)* while providing clinically valid results.
        </Paragraph>
      </HeadingWrapper>
      <LeftRightWrapper>
        <Left>
          <Img
            fluid={
              isDesktop
                ? comfortableTestingHero.childImageSharp.fluid
                : mobileComfortableTestingHero.childImageSharp.fluid
            }
            imgStyle={{ objectFit: 'contain' }}
          />
        </Left>
        <Right>
          <IconList items={listItems} />
        </Right>
      </LeftRightWrapper>
    </Section>
  )
}

export default ComfortableTesting
