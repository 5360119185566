import React, { FC, ReactNode } from 'react'

import { Wrap, ListItem, Paragraph, Title, TextWrap, IconWrap } from './styled'

type Item = {
  icon: ReactNode
  subheading: string
  text: string
  html?: boolean
}

type Props = {
  items: Item[]
}

const IconList: FC<Props> = ({ items }) => {
  return (
    <Wrap>
      {items.map(item => (
        <ListItem key={item.subheading}>
          <IconWrap>{item.icon}</IconWrap>
          <TextWrap>
            <Title>{item.subheading}</Title>
            {item.html ? (
              <Paragraph dangerouslySetInnerHTML={{ __html: item.text }} />
            ) : (
              <Paragraph>{item.text}</Paragraph>
            )}
          </TextWrap>
        </ListItem>
      ))}
    </Wrap>
  )
}

export default IconList
