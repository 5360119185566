import React, { FC } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import Urls from 'common/urls'
import { Wrapper, Left, Right, Title, Paragraph, ButtonWrapper } from './styled'
import { Section, SectionEyebrow } from '../styled'
import { ExternalLink } from 'components/links'

type QueryResult = {
  heroDesktop: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const query = graphql`
  query {
    heroDesktop: file(relativePath: { eq: "providers-hero-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 1400, webpQuality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Hero: FC = () => {
  const { heroDesktop } = useStaticQuery<QueryResult>(query)
  return (
    <Section lightBlue>
      <Wrapper>
        <Left>
          <SectionEyebrow>For Providers</SectionEyebrow>
          <Title>
            One clinically validated mail-in semen analysis with a world of
            benefits
          </Title>
          <Paragraph>
            Fellow&apos;s mail-in semen analysis tests optimize workflows for
            your clinic and staff, improve the patient experience,<sup>†‡</sup>
            and yield valuable insights into your patient&apos;s health and
            fertility.
          </Paragraph>
          <ButtonWrapper>
            <ExternalLink to={Urls.leadGenForm}>Get in touch</ExternalLink>
          </ButtonWrapper>
        </Left>
        <Right>
          <Img
            fluid={heroDesktop.childImageSharp.fluid}
            imgStyle={{ objectFit: 'contain' }}
            fadeIn={false}
            loading="eager"
          />
        </Right>
      </Wrapper>
    </Section>
  )
}

export default Hero
