import React from 'react'
import {
  Wrapper,
  TextContainer,
  Headline,
  CTAButton,
} from './styled'

const StickyFooter = () => {
  const handleClick = () => {
    window.open('https://meetfellow.typeform.com/to/C7IB6L', '_blank')
  }
  return (
    <Wrapper>
      <TextContainer>
        <Headline>
          Learn more about working with<span translate="no"> Fellow</span>
        </Headline>
      </TextContainer>
      <CTAButton onClick={handleClick}>Get in touch</CTAButton>
      {/* <a
          href="https://meetfellow.typeform.com/to/C7IB6L"
          target="_blank"
          rel="noreferrer noopener"
        >
          <CTAButton>Request a demo</CTAButton>
        </a> */}
    </Wrapper>
  )
}

export default StickyFooter
