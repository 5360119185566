import React, { FC } from 'react'
import Svg from 'components/svg'

const ClockWithSperm: FC = props => (
  <Svg {...props} viewbox="-40 0 200 140">
    <path
      stroke="currentColor"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.75"
      d="M65.488 45.856h38.746c.628 0 1.148.61 1.148 1.372V99.05c0 4.315-2.935 7.82-6.547 7.82h-47.92c-3.612 0-6.547-3.505-6.547-7.82V77.145M64.705 55.243h40.676M44.368 81.145h6.258M44.368 89.661h6.258"
    />
    <path
      stroke="currentColor"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="1.75"
      d="M33.288 79.162c17.833 0 32.289-14.363 32.289-32.08C65.577 29.362 51.12 15 33.288 15 15.456 15 1 29.363 1 47.081 1 64.8 15.456 79.162 33.288 79.162ZM49.062 30.212 33.416 48.984M22.465 42.727l10.951 6.258"
    />
    <path
      fill="currentColor"
      d="M67.31 76.58c0 3.827-1.708 4.997-3.817 4.997-2.108 0-3.816-1.392-3.816-4.997 0-2.528 2.261-4.565 3.816-4.565 1.732 0 3.817 2.048 3.817 4.565Z"
    />
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.75" d="M63.506 81.577v17.334"/>
    <path fill="currentColor" d="M80.41 69.194c0 3.828-1.708 4.998-3.817 4.998s-3.817-1.393-3.817-4.998c0-2.528 2.262-4.564 3.817-4.564 1.732 0 3.817 2.048 3.817 4.564Z"/>
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.75" d="M76.592 74.204v17.24"/>
    <path fill="currentColor" d="M93.497 76.58c0 3.827-1.709 4.997-3.817 4.997-2.109 0-3.817-1.392-3.817-4.997 0-2.528 2.262-4.565 3.817-4.565 1.732 0 3.817 2.048 3.817 4.565Z"/>
    <path stroke="currentColor" strokeMiterlimit="10" strokeWidth="1.75" d="M89.692 81.577v17.334"/>
    <circle cx="33.5" cy="48.5" r="2.5" fill="currentColor"/>
  </Svg>
)

export default ClockWithSperm
